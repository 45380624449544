import request from '@/utils/request'

const muck_recordApi = {
  muck_record_list: '/muck_record/',
  muck_record_create: '/muck_record/',
  muck_record_delete: '/muck_record/'
}

/**
 * 列表
 */
export function muck_record_list (parameter) {
  return request({
    url: muck_recordApi.muck_record_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function muck_record_create (parameter) {
  return request({
    url: muck_recordApi.muck_record_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function muck_record_delete (muck_record_id) {
  return request({
    url: muck_recordApi.muck_record_delete + muck_record_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
